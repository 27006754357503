import axios from "axios";
import { LocalStorage } from "./HelperFunction";
import { API_URL } from "./API_URL";
import toast from "react-hot-toast";

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_SERVER
    : process.env.REACT_APP_LOCAL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
const refreshToken = async () => {
  const refreshToken = LocalStorage.get("refreshToken");
  if (!refreshToken) {
    LocalStorage.remove("userToken");
    window.location.replace("/");
    return;
  }
  
  const data = await axiosInstance.get(API_URL.REFRESH_TOKEN, {
    token: refreshToken,
  });

  return data.data;
};
// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    let token = config.token;
    if (!token) {
      token = LocalStorage.get("userToken");
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("request Error",error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error);
    const originalRequest = error.config;
    if (
      error?.response?.data?.message === "Token Expired" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const result = await refreshToken();
        console.log(77,result);
        if (result.status) {
          const newToken = result.data;
          LocalStorage.set("userToken", newToken?.token);
          newToken?.refreshToken &&LocalStorage.set("refreshToken", newToken?.refreshToken);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newToken.token}`;
          originalRequest.headers["Authorization"] = `Bearer ${newToken.token}`;
          return axiosInstance(originalRequest);
        } else {
          LocalStorage.clear();
          window.location.replace("/");
          return Promise.reject(result);
        }
      } catch (refreshError) {
        LocalStorage.clear();
        window.location.replace("/");
        return Promise.reject(refreshError);
      }
    } else if (
      error?.response?.data?.message ===
      "Your are not authorized to perform this action, please contact your administrator"
    ) {
      window.location.replace("/no-auth");
    } else if (error?.response?.data?.message === "Invalid token") {
      LocalStorage.clear();
      window.location.replace("/");
    }
    if (error.code === "ERR_NETWORK") {
      toast.error("Server stopped due to network ");
    }
    // console.log("res error")
    return Promise.reject(error);
  }
);

export default axiosInstance;
