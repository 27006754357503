import { TailSpin } from "react-loader-spinner";

function PageLoader() {
  return (
    <div
      style={{ height: "80vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#7B76F1"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}

export default PageLoader;
