import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as yup from "yup"
import { Button, Image, Modal, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import imageCompression from "browser-image-compression";
import { Icon } from "@iconify/react";
import {
  Helper,
  S3_URL,
  capitalizeEveryWord,
  numberField,
  validateFields,
} from "../../helper/HelperFunction";
import { API_URL } from "../../helper/API_URL";
import UserImg from "../../assets/image/user.png";
import Tags from "../../components/Tag/Tags";

const defaultValues = {
  name: "",
  count: "",
};
const defaultErrValues = {
  name: false,
  count: false,
};
const userSchema = yup.object().shape({
  name: yup.string().required("Enter regerral name"),
  count: yup.number().min(1).max(100).required("Enter token count"),
});
const AddReferral = ({ show, onHide, data }) => {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user);
  const [formObj, setFormObj] = useState(defaultValues);
  const [errorObj, setErrorObj] = useState(defaultErrValues);
  const [loader, setLoader] = useState(false);

  const generateToken = async () => {
    try {
      const errors = await validateFields(userSchema, formObj);
      if (Object.keys(errors).length > 0) {
        return setErrorObj(errors);
      }
      setLoader(true);
      const url = API_URL.GENERATE_TOKEN;
      const onSuccess = (data) => {
        if (data) {
          navigate("tokens",{state:{data:data.data,name:formObj.name,count:formObj.count}})
        }
      };
      const onError = (res) => {
        res?.data?.message&&toast.error(res?.data?.message);
      };
      const result = await Helper.PostAPI(url, formObj, onSuccess, onError);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };
  const handleInputChange = (event) => {
    setErrorObj(defaultErrValues);
    const { name, value } = event.target;
    if (name === "count") {
      const num = numberField(value)
      setFormObj({
        ...formObj,
        [name]: num,
      });
    } else {
      setFormObj({
        ...formObj,
        [name]: value.trimStart(),
      });
    }
  };
  const isDisabled = () => {
    if (!formObj.name.trim() || !formObj.count || loader) {
      return {
        disabled: true,
      };
    }
    return {
      disabled: false,
    };
  };
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal_css modal_cssWidth"
    >
      <Modal.Header>
        <div className="d-flex align-items-center w-100 justify-content-between">
          <div className="uFlex">
            <h5 className="pointer" onClick={() => navigate("/profile2")}>
              Add
            </h5>
          </div>

          <Icon
            className="pointer"
            icon="prime:times-circle"
            onClick={onHide}
            fontSize={35}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3 mt-3 des-shadow">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            value={formObj.name}
            onChange={handleInputChange}
            placeholder="Enter name"
          />
          {errorObj.name && <div className="error">{errorObj.name}</div>}
        </Form.Group>
        <Form.Group className="mb-3 mt-3 des-shadow">
          <Form.Label>Referral</Form.Label>
          <Form.Control
            name="count"
            value={formObj.count}
            onChange={handleInputChange}
            placeholder="Enter referral count"
          />
          {errorObj.count && <div className="error">{errorObj.count}</div>}
        </Form.Group>
        <div className="mod_foot">
          <Button
            className="w-100"
            variant="primary"
            disabled={isDisabled().disabled}
            style={{
              opacity: isDisabled().disabled ? 0.5 : 1,
            }}
            onClick={generateToken}
          >
            Generate Invite Code
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddReferral;
