import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import not_found from "../../assets/image/not_found.svg";
import { Helper } from "../../helper/HelperFunction";
import { API_URL } from "../../helper/API_URL";
import PageLoader from "../../components/PageLoader";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const DataTable1 = ({ data1, name, count, setToken }) => {
  const navigate = useNavigate();
  const generateToken = async () => {
    try {
      const url = API_URL.GENERATE_TOKEN;
      const onSuccess = (data) => {
        if (data) {
          setToken(data.data);
        }
      };
      const onError = (res) => {
        res?.data?.message && toast.error(res?.data?.message);
      };
      const result = await Helper.PostAPI(
        url,
        { name, count },
        onSuccess,
        onError
      );
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = API_URL.CREATE_REFERRAL;
      const obj = {
        name,
        count,
        tokens:data1
      };
      const onSuccess = (data) => {
        if (data) {
          navigate(-1);
        }
      };
      const onError = (res) => {
        res?.data?.message && toast.error(res?.data?.message);
      };
      const result = await Helper.PostAPI(
        url,
        obj,
        onSuccess,
        onError
      );
    } catch (err) {
      console.log(err);
      toast.error("App error")
    }
  };
  return (
    <>
      <div className="table-responsive scroll">
        {data1?.length > 0 ? (
          <table className="table tablecustom">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Invite Code</th>
              </tr>
            </thead>
            <tbody>
              {data1?.map((item, index) => (
                <tr key={item.id}>
                  {/* <td>{item.id}</td> */}
                  <td>{index + 1}</td>
                  <td>{item}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="noresult_div">
            <img src={not_found} />
            <h3>No Data Found</h3>
          </div>
        )}
      </div>
      <div className="my-3 text-end">
        <Button className="cancel_btn" onClick={generateToken}>
          Regenerate
        </Button>
        <Button className="ms-3 login_btn" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </>
  );
};
const Tokens = () => {
  const [token, setToken] = useState(null);
  const location = useLocation();
  const { data, name, count } = location.state;

  useEffect(() => {
    setToken(data);
  }, [data]);
  console.log(5555999, data);

  return !token ? (
    <PageLoader />
  ) : (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>Invite Codes</h1>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <DataTable1
          data1={token}
          name={name}
          count={count}
          setToken={setToken}
        />
      </div>
    </>
  );
};

export default Tokens;
