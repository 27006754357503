import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import not_found from "../../../assets/image/not_found.svg";
import Recover from "../../../assets/image/recovery.svg";
import edit from "../../../assets/image/edit.svg";
import trash from "../../../assets/image/trash.svg";
import { Helper, capitalizeEveryWord } from "../../../helper/HelperFunction";
import { API_URL } from "../../../helper/API_URL";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader";
import ActionButton from "../../Include/ActionButton";

const DataTable1 = ({ data, fetchData, Permission }) => {
  const handleDelete = async (id, status) => {
    try {
      const url = API_URL.UPDATE_MAJOR;
      const obj = {
        id,
        status: status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      };
      const result = await Helper.PutData(url, obj);
      if (result.data?.status) {
        fetchData();
      } else {
        toast.error(result.response?.data?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="table-responsive">
      {data.length > 0 ? (
        <table className="table tablecustom">
          <thead>
            <tr>
              <th>Major</th>
              <th>Status</th>
              {Permission.Major?.edit && <th className="text-end">Action</th>}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.status}</td>
                {Permission.Major?.edit && (
                  <td className="text-end">
                    <ActionButton
                      id={`edit-${index}`}
                      icon={edit}
                      tooltipText="Edit"
                      className="edit-btn"
                      link={`edit?key=${item._id}`}
                    />
                    {Permission.Major?.delete && (
                      <span onClick={() => handleDelete(item._id, item.status)}>
                        <ActionButton
                          id={`trash-${index}`}
                          icon={item.status === "ACTIVE" ? trash : Recover}
                          tooltipText={
                            item.status === "ACTIVE" ? "Delete" : "Recover"
                          }
                          className="delete-btn"
                          link="#"
                        />
                      </span>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="noresult_div">
          <img src={not_found} />
          <h3>No Data Found</h3>
        </div>
      )}
    </div>
  );
};
const Major = () => {
  const Permission = useSelector((state) => state.Permissions);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [searchTerm]);
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const fetchData = async () => {
    try {
      const url = `${API_URL.GET_MAJOR}?filter=${searchTerm}&page=${currentPage}`;
      const result = await Helper.GetData(url);
      if (result.data.status) {
        setData(result.data.data.result);
        setTotalCount(result.data.data.total_count);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="welcome_Div">
            <Row>
              <Col lg="8">
                <h1>Majors</h1>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <Row>
              <Col md="6" lg="4">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  className="form-control"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Col>
              {Permission.Major?.add && (
                <Col md="6" lg="8" className="text-start text-md-end">
                  <Link to="add">
                    <Button color="primary" className="px-3">
                      Add
                    </Button>
                  </Link>
                </Col>
              )}
            </Row>

            <DataTable1
              data={data}
              Permission={Permission}
              currentPage={currentPage}
              fetchData={fetchData}
            />
            {data?.length > 0 && (
              <div className="mb-3">
                <div className="pagination mt-3">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    ‹
                  </button>
                  {currentPage > 3 &&
                    Math.ceil(totalCount / itemsPerPage) > 5 && (
                      <button onClick={() => handlePageChange(1)}>1</button>
                    )}
                  {currentPage > 4 &&
                    Math.ceil(totalCount / itemsPerPage) > 5 && (
                      <span className="pagination-ellipsis">...</span>
                    )}

                  {Array.from({
                    length: Math.min(5, Math.ceil(totalCount / itemsPerPage)),
                  }).map((_, index) => {
                    let startingIndex =
                      currentPage <= 3
                        ? 1
                        : currentPage >=
                          Math.ceil(totalCount / itemsPerPage) - 2
                        ? Math.ceil(totalCount / itemsPerPage) - 4
                        : currentPage - 2;

                    const pageNumber =
                      startingIndex === 0 ? 1 + index : startingIndex + index;

                    return (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={currentPage === pageNumber ? "active" : ""}
                      >
                        {pageNumber}
                      </button>
                    );
                  })}
                  {Math.ceil(totalCount / itemsPerPage) > 6 &&
                    currentPage < Math.ceil(totalCount / itemsPerPage) - 3 && (
                      <span className="pagination-ellipsis">...</span>
                    )}
                  {currentPage <= Math.ceil(totalCount / itemsPerPage) - 3 &&
                    Math.ceil(totalCount / itemsPerPage) > 5 && (
                      <button
                        onClick={() =>
                          handlePageChange(Math.ceil(totalCount / itemsPerPage))
                        }
                      >
                        {Math.ceil(totalCount / itemsPerPage)}
                      </button>
                    )}

                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage === Math.ceil(totalCount / itemsPerPage)
                    }
                  >
                    ›
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Major;
