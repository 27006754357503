import React, { useState } from "react";
import { Row, Col, Label, Form, FormGroup, Input, Button } from "reactstrap";
import * as yup from "yup";
import { Helper, validateFields } from "../../../helper/HelperFunction";
import { API_URL } from "../../../helper/API_URL";
import { useNavigate, Link } from "react-router-dom";
import { ChevronLeft } from "react-feather";


const defaultValues = {
  name: ""
};
const defaultErrorValues = {
  name: false
};
const roleSchema = yup.object().shape({
  name: yup.string().required("Enter major")
});
const AddMajor = () => {
  const navigate = useNavigate();
  const [formObj, setFormObj] = useState(defaultValues);
  const [erroObj, setErrorObj] = useState(defaultErrorValues);
  const [saving, setSaving] = useState(false);

  const handleInputChange = (event) => {
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;
    setFormObj({
      ...formObj,
      [name]: value.trimStart(),
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const errors = await validateFields(roleSchema, formObj);
      if (Object.keys(errors).length > 0) {
        return setErrorObj(errors);
      }
      const url = API_URL.CREATE_MAJOR;
      const onSuccess = (data) => {
        if (data) {
          navigate(-1);
        }
      };
      const onError = (res) => {
        setErrorObj((prev)=>({...prev,name:res?.data?.message}))
      };
      await Helper.PostAPI(url, formObj, onSuccess, onError);
    } catch (err) {
      console.log(err);
    }finally{
      setSaving(false);
    }
  };
  return (
        <>
          <div className="welcome_Div">
            <Row>
              <Col lg="8" className="breadcrumbtop">
                <Link onClick={() => navigate(-1)}>
                  <ChevronLeft />
                </Link>
                <div>
                  <h1>Add Major</h1>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col lg="8">
              <Form onSubmit={handleSubmit}>
                <FormGroup className="form-group">
                  <Label className="mb-1">Major</Label>
                  <Input
                    className="form-control"
                    name="name"
                    value={formObj.name}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter major"
                  ></Input>
                  {erroObj.name && <div className="error">{erroObj.name}</div>}
                </FormGroup>
                <div>
                  <Link onClick={(e) => navigate(-1)}>
                    <Button className="cancel_btn px-3 min-width-auto me-1">
                      Back
                    </Button>
                  </Link>
                  <Button
                    className="login_btn  px-3 min-width-auto ms-1"
                    color="primary"
                    type="submit"
                  >
                    {saving?"Adding...":"Add"}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </>
      
  );
};

export default AddMajor;
