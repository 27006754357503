import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./config.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./officer/assets/index.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./store/userSlice.js";
import Loader from "./components/Loader.jsx";
import PrivateRoute from "./routes/PrivateRoute.jsx";
import PublicRoute from "./routes/PublicRoute.jsx";
import TwoFactorRoute from "./routes/TwoFactorRoute.jsx";
import { LocalStorage } from "./helper/HelperFunction.js";
import PageLoader from "./components/PageLoader.jsx";
import { fetchMajor } from "./store/majorSlice.js";
import SchoolRoute from "./routes/SchoolRoute.jsx";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector((state) => state.user);

  const userToken = LocalStorage.get("userToken");
  const token = LocalStorage.get("token");

  useEffect(() => {
    dispatch(fetchMajor());
  }, []);
  useEffect(() => {
    if (userToken) {
      setLoading(true);
      dispatch(fetchUserData());
      if (userDetails._id) {
        setLoading(false);
      }
    }
  }, [userToken, token]);
  useEffect(() => {
    if (userToken) {
      if (userDetails._id) {
        setLoading(false);
      }
    }
  }, [userDetails]);

  return loading ? (
    <Loader />
  ) : (
    <Suspense fallback={<PageLoader />}>
      <Router>
        {userToken ? (
          userDetails?.role?.role === "school" ? (
            <SchoolRoute />
          ) : (
            <PrivateRoute />
          )
        ) : token ? (
          <TwoFactorRoute />
        ) : (
          <PublicRoute />
        )}
      </Router>
    </Suspense>
  );
}

export default App;
