export const API_URL = {
  LOGIN: "admin/login",
  VERIFY_OTP: "admin/otp",
  DASHBOARD: "admin/dashboard",
  SCHOOL_DASHBOARD: "school/dashboard",
  ENABLE_DISABLE_CHAT:"school/enable-disable-chat",
  PROFILE: "admin/get-user-profile",
  POST_LIST: "admin/get-posts",
  SCHOOL_POST_LIST: "school/get-posts",
  DELETE_POST: "admin/delete-post",
  COMMENT_LIST: "admin/get-comments",
  SCHOOL_COMMENT_LIST: "school/get-comments",
  DELETE_COMMENT: "admin/delete-comment",
  GROUP_LIST: "admin/get-groups",
  SCHOOL_GROUP_LIST: "school/get-groups",
  DELETE_GROUP: "admin/delete-group",
  PROJECT_LIST: "admin/get-projects",
  SCHOOL_PROJECT_LIST: "school/get-projects",
  DELETE_PROJECT: "admin/delete-project",
  USER_LIST: "admin/get-user-list",
  SCHOOL_USER_LIST: "school/get-user-list",
  STUDENT_LIST: "school/get-student-list",
  CREATE_USER: "admin/create-user",
  CREATE_SCHOOL: "school/create-user",
  REPORT_LIST: "admin/get-reports",
  FORGOT_PASSWORD: "admin/forgot-password",
  RESEND_OTP: "admin/resend-otp",
  RESEND_FORGOT_OTP: "admin/resend-forgot-otp",
  VERIFY_FORGOT_PASSWORD_OTP: "admin/verify-forgot-password-otp",
  RESET_PASSWORD: "admin/reset-password",
  CHANGE_PASSWORD: "admin/change-password",
  VIEW_USER: "admin/get-user",
  UPDATE_USER: "admin/update-profile",
  BLOCK_USER: "admin/block-unblock-user",
  CREATE_ROLE: "admin/create-role",
  GET_ROLE: "admin/get-role",
  GET_SIGNED_URL: `user/signed-url`,
  UPDATE_ROLE: "admin/update-role",
  DELETE_ROLE: "admin/delete-role",
  ADD_USER: "admin/add-user",
  UPDATE_ORG_USER: "admin/update-org-user",
  GET_TERMS: "admin/get-terms",
  GET_PRIVACY: "admin/get-privacy",
  UPDATE_TERMS: "admin/update-terms",
  UPDATE_PRIVACY: "admin/update-privacy",
  CHAT_LIST: "admin/get-chats",
  CREATE_POST: "admin/add-post",
  UPDATE_POST: "admin/update-post",
  CREATE_FAQ_CAT: "admin/add-faq-cat",
  UPDATE_FAQ_CAT: "admin/edit-faq-cat",
  UPDATE_FAQ_CAT_ORDER: "admin/edit-faq-cat-order",
  GET_FAQ_CAT: "admin/get-faq-cat",
  DELETE_FAQ_CAT: "admin/delete-faq-cat",
  CREATE_FAQ: "admin/add-faq",
  UPDATE_FAQ: "admin/edit-faq",
  UPDATE_FAQ_ORDER: "admin/edit-faq-order",
  GET_FAQ: "admin/get-faq",
  DELETE_FAQ: "admin/delete-faq",
  CREATE_RESOURCE_CAT: "admin/add-resource-cat",
  UPDATE_RESOURCE_CAT: "admin/edit-resource-cat",
  UPDATE_RESOURCE_CAT_ORDER: "admin/edit-resource-cat-order",
  GET_RESOURCE_CAT: "admin/get-resource-cat",
  DELETE_RESOURCE_CAT: "admin/delete-resource-cat",
  CREATE_RESOURCE: "admin/add-resource",
  UPDATE_RESOURCE: "admin/edit-resource",
  UPDATE_RESOURCE_ORDER: "admin/edit-resource-order",
  GET_RESOURCE: "admin/get-resource",
  DELETE_RESOURCE: "admin/delete-resource",
  // CREATE_SCHOOL: "admin/add-school",
  UPDATE_SCHOOL: "admin/edit-school",
  GET_SCHOOL: "admin/get-school",
  DELETE_SCHOOL: "admin/delete-school",
  CREATE_MAJOR: "admin/add-major",
  UPDATE_MAJOR: "admin/edit-major",
  GET_MAJOR: "admin/get-major",
  MAJOR_LIST: "user/major",
  DELETE_MAJOR: "admin/delete-major",
  GENERATE_TOKEN: "admin/generate-token",
  REFRESH_TOKEN:"user/refreshtoken",
  REFERRAL_LIST:"admin/referral-list",
  CREATE_REFERRAL:"admin/create-referral",
  DELETE_REFERRAL:"admin/delete-referral",
  TOKEN_LIST:"admin/token-list"
};
