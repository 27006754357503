import { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

const Login = lazy(() => import("../pages/authentication/Login"))
const Otp = lazy(() => import("../pages/authentication/Otp"));

const TwoFactorRoute = ({ state, setState }) => {
  return (
      <Routes>
        <Route path="/otp" element={<Otp />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Login state={state} setState={setState} />} />
      </Routes>
  );
};

export default TwoFactorRoute;
