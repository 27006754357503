import { createSlice } from "@reduxjs/toolkit";

const initialState = {
};
export const permissionSlice = createSlice({
    name:"permissions",
    initialState,
    reducers:{
        UpdatePermissions:(state,action)=>{
            return {...state,...action.payload}
        },
    }
})

export const {UpdatePermissions}=permissionSlice.actions;
export default permissionSlice.reducer;