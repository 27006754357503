import { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

const Login = lazy(() => import("../pages/authentication/Login.jsx"));
const ForgotPassword = lazy(() =>
  import("../pages/authentication/ForgotPassword.jsx")
);
const ForgotOtp = lazy(() => import("../pages/authentication/ForgotOtp.jsx"));
const ResetPassword = lazy(() =>
  import("../pages/authentication/ResetPassword.jsx")
);

const PublicRoute = () => {
  return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/verify-forgot-otp" element={<ForgotOtp />} />
        <Route path="/reset-password/:email/:otp" element={<ResetPassword />} />
      </Routes>
  );
};

export default PublicRoute;
