import toast from "react-hot-toast";
import axiosInstance from "./AxiosInstance";
import { IMAGES } from "../officer/components/Images";
const isBrowser = typeof window !== "undefined";

export const Helper = {
  PostAPI: async (url, obj, successFun, errorFun) => {
    try {
      const result = await axiosInstance.post(url, obj);
      if (result.data?.status) {
        successFun(result.data);
      }
      return;
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "Your are not authorized to perform this action, please contact your administrator"
      ) {
        window.location.replace("/no-auth");
      } else if (err?.response?.status === 400 && err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
      console.log(err);
      errorFun(err?.response);
      return;
    }
  },
  PatchData: async (url, obj) => {
    try {
      return await axiosInstance.patch(url, obj);
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "Your are not authorized to perform this action, please contact your administrator"
      ) {
        window.location.replace("/no-auth");
      } else if (err?.response?.status === 400 && err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
      console.log(err);
      return err;
    }
  },
  PutData: async (url, obj) => {
    try {
      return await axiosInstance.put(url, obj);
    } catch (err) {
      console.log(err);
      if (
        err?.response?.data?.message ===
        "Your are not authorized to perform this action, please contact your administrator"
      ) {
        window.location.replace("/no-auth");
      } else if (err?.response?.status === 400 && err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
      return err;
    }
  },
  DeleteData: async (url) => {
    try {
      return await axiosInstance.delete(url);
    } catch (err) {
      if (err?.response?.data?.message === "Invalid token") {
        window.location.replace("/login");
      } else if (
        err?.response?.data?.message ===
        "Your are not authorized to perform this action, please contact your administrator"
      ) {
        window.location.replace("/no-auth");
      } else if (err?.response?.status === 400 && err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
      console.log(err);
      return err;
    }
  },
  GetData: async (url) => {
    try {
      return await axiosInstance.get(url);
    } catch (err) {
      console.error(err);
      if (err?.response?.data?.message === "Invalid token") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace("/");
      }
      if (
        err?.response?.data?.message ===
        "Your are not authorized to perform this action, please contact your administrator"
      ) {
        window.location.replace("/no-auth");
      }
      return err;
    }
  },
  GetAPI: async (url, successFun, errorFun) => {
    try {
      const result = await axiosInstance.get(url);
      if (result.data?.status) {
        successFun(result.data);
      }
      return;
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "Your are not authorized to perform this action, please contact your administrator"
      ) {
        window.location.replace("/no-auth");
      } else if (err?.response?.status === 400 && err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
      console.log(err);
      errorFun(err?.response);
      return;
    }
  },
  PostFormData: async (url, obj) => {
    try {
      return await axiosInstance.post(url, obj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
      if (
        err?.response?.data?.message ===
        "Your are not authorized to perform this action, please contact your administrator"
      ) {
        window.location.replace("/no-auth");
      } else if (err?.response?.status === 400 && err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
      return err;
    }
  },
};

export class LocalStorage {
  static get(key) {
    if (!isBrowser) return;
    const value = localStorage.getItem(key) || sessionStorage.getItem(key);
    if (value) {
      try {
        return value;
      } catch (err) {
        return null;
      }
    }
    return null;
  }
  static set(key, value) {
    if (!isBrowser) return;
    localStorage.setItem(key, value);
  }
  static setSession(key, value) {
    if (!isBrowser) return;
    sessionStorage.setItem(key, value);
  }
  static remove(key) {
    if (!isBrowser) return;
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }
  static clear() {
    if (!isBrowser) return;
    localStorage.clear();
    sessionStorage.clear();
  }
}
// export const S3_URL = "https://briddggg.s3.amazonaws.com/";
export const S3_URL = "https://briddggg.s3-accelerate.amazonaws.com/";
export const validateFields = async (validationSchema, values) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
    return {};
  } catch (validationErrors) {
    const errors = {};
    validationErrors.inner.forEach((error) => {
      if (error.path) {
        errors[error.path] = error.message;
      }
    });
    return errors;
  }
};
export const shortenSentence = ( sentence,length, dot) => {
  const len = length || 15;
  const words = sentence?.split("");
  if (words?.length > len) {
    return dot
      ? words?.slice(0, len).join("")
      : words?.slice(0, len).join("") + "...";
  } else {
    return sentence;
  }
};
export const shortenTitle = (sentence) => {
  const words = sentence?.split("");
  if (words?.length > 15) {
    return words?.slice(0, 15).join("");
  } else {
    return sentence;
  }
};
export const shortenDescription = (sentence) => {
  const words = sentence?.split("");
  if (words?.length > 55) {
    return words?.slice(0, 55).join("") + "...";
  } else {
    return sentence;
  }
};
export const getImage = (media,type) => {
  if(!type){
    return IMAGES.user;
  }
  if(media){
    return S3_URL+media;
  }else{
    return IMAGES[type];
  }
};
export const localDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
};
export const capitalizeEveryWord = (sentence) => {
  const words = sentence?.split(" ");
  const capitalizedWords = words?.map((word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  });
  const capitalizedSentence = capitalizedWords?.join(" ");
  return capitalizedSentence;
};
export const formatDateToLocalISOString = (date) => {
  if (date) {
    const offsetInMinutes = date.getTimezoneOffset();
    const localISOString = new Date(
      date.getTime() - offsetInMinutes * 60000
    ).toISOString();
    return localISOString.split("T")[0];
  }
};

export const testAPI = () => {
  return {
    recipeName: "Burger",
    description: "It is not good to have",
    time: "45",
    totalServing: "3",
    caloryPerServing: "110",
    recipeImage: ["1706539730903.png"],
    nutritions: [
      {
        name: "protein",
        value: "6",
      },
      {
        name: "fat",
        value: "26",
      },
      {
        name: "carbohydrate",
        value: "6",
      },
    ],
    ingredients: [
      {
        name: "1 Slice Turkey Bacon, cut into small pieces",
        value: "1.7",
      },
      {
        name: "1 clove Garlic, minced",
        value: "1.8",
      },
      {
        name: "1 lb Mustard Greens, tough stems discarded, roughly chopped",
        value: "0.7",
      },
      {
        name: "1 tsp. Salt (optional)",
        value: "0.7",
      },
      {
        name: "Pepper to taste",
        value: "0.7",
      },
      {
        name: "1/2 cup Low Sodium Chicken broth",
        value: "0.7",
      },
      {
        name: "1 TBSP Lemon juice",
        value: "0.7",
      },
    ],
    instructions: [
      "heat a large amount of times",
      "heat a large amount of times",
      "heat a large amount of times",
      "heat a large amount of times",
      "heat a large amount of times",
    ],
    comments: [
      "heat a large amount of times hhhuiy this is not good enough",
      "heat a large amount of times",
      "heat a large amount of times",
    ],
    url: "hhhh.com",
  };
};
export const moduleArray = [
  "Dashboard",
  "User",
  "Post",
  "Comment",
  "Group",
  "My Passion Project",
  "Report",
  "Role",
  "Referral",
  "Organization User",
  "Chat",
  "Content Management",
  "Admin Post",
  "Term & Condition",
  "Privacy Policy",
  "Faq",
  "Resource",
  "School",
  "Major",
];
export const numberField = (value) => {
  return Number(value.replace(/[^0-9]/g, ""));
};
