import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper } from "../helper/HelperFunction";
import { API_URL } from "../helper/API_URL";

const initialState = {
  Major: [],
};

const fetchMajor = createAsyncThunk("major/fetchMajor", async () => {
  try {
    const url = API_URL.MAJOR_LIST;
    const result = await Helper.GetData(url);
    if (result.data?.status) {
      return result.data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
});
export const majorSlice = createSlice({
  name: "major",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMajor.fulfilled, (state, action) => {
      return {
        ...state,
        Major: action.payload,
      };
    });
  },
});
export { fetchMajor };
export default majorSlice.reducer;
