import React, { useEffect, useState } from "react";
import { Row, Col, Button, FormGroup, Form, Input, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { ChevronLeft, Edit2 } from "react-feather";
import { API_URL } from "../../../helper/API_URL";
import { Helper, validateFields } from "../../../helper/HelperFunction";
import toast from "react-hot-toast";

const defaultErrorValues = {
  name: false,
  count: false,
};
const defaultValues = {
  name: "",
  count: "",
};
const userSchema = yup.object().shape({
  name: yup.string().required("Enter regerral name"),
  count: yup.string().required("Enter token count"),
});
const AddReferral = (args) => {
  const navigate = useNavigate();
  const [formObj, setFormObj] = useState(defaultValues);
  const [errorObj, setErrorObj] = useState(defaultErrorValues);
  const [tokenList, setTokenList] = useState(null);

  const generateToken = async () => {
    try {
      const url = API_URL.GENERATE_TOKEN;
      const onSuccess = (data) => {
        if (data) {
          setTokenList(data.data);
        }
      };
      const onError = (res) => {
        toast.error(res?.data?.message);
      };
      const result = await Helper.PostAPI(url, formObj, onSuccess, onError);
    } catch (err) {
      console.log(err);
    }
  };
  const handleInputChange = (event) => {
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;
    if (name === "count") {
      setFormObj({
        ...formObj,
        [name]: +value,
      });
    } else {
      setFormObj({
        ...formObj,
        [name]: value.trimStart(),
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("obj");
      const errors = await validateFields(userSchema, formObj);
      if (Object.keys(errors).length > 0) {
        console.log("1", errors);
        return setErrorObj(errors);
      }
      console.log("1");
      const url = API_URL.ADD_USER;
      if (formObj.password !== formObj.confirm_password) {
        return setErrorObj((prev) => ({ ...prev, confirm_password: true }));
      }
      const obj = {
        firstName: formObj.firstName,
        lastName: formObj.lastName,
        email: formObj.email,
        dob: new Date(formObj.dob),
        // file: imageFile,
        mobileNumber: formObj.mobileNumber,
        password: formObj.password,
        country: formObj.country,
        countryCode: formObj.countryCode,
        stateCode: formObj.stateCode,
        city: formObj.city,
        state: formObj.state,
        zipCode: formObj.zipCode,
        // role: formObj.role,
      };
      console.log(obj);
      const formData = new FormData();
      for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
          const value =
            obj[key] instanceof Date ? obj[key].toISOString() : obj[key];
          formData.append(key, value);
        }
      }

      const result = await Helper.PostFormData(url, formData);
      if (result.data.status) {
        navigate("/users");
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log("role", tokenList);
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8" className="breadcrumbtop">
            <Link to="/Users">
              <ChevronLeft />
            </Link>
            <div>
              <h1>Add Referrals</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="8">
            <Form onSubmit={handleSubmit}>
              <Row className="mt-3">
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label mb-1">Referral Name</Label>
                    <Input
                      name="name"
                      placeholder="Referral Name"
                      value={formObj.name}
                      onChange={handleInputChange}
                      type="text"
                    />
                    {errorObj.name && (
                      <div className="error">{errorObj.name}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label  mb-1">Token Count</Label>
                    <Input
                      name="count"
                      placeholder="Enter token count"
                      value={formObj.count}
                      onChange={handleInputChange}
                      type="text"
                    />
                    {errorObj.count && (
                      <div className="error">{errorObj.count}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {tokenList?.length > 0 && <Row className="mt-3"><Col>
              <p>gggggggg</p>
              </Col></Row>}
              <div className="d-flex">
                <Link to="/Users">
                  <Button className="cancel_btn mt-4 me-2" type="submit">
                    Cancel
                  </Button>
                </Link>
                {!tokenList && (
                  <Button
                    className="login_btn mt-4 me-2"
                    onClick={generateToken}
                    color="primary"
                  >
                    Generate Token
                  </Button>
                )}
                {tokenList && (
                  <>
                    <Button
                      className="login_btn mt-4 me-2"
                      onClick={generateToken}
                      color="primary"
                    >
                      Regenerate Token
                    </Button>
                    <Button
                      className="login_btn mt-4"
                      type="submit"
                      color="primary"
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddReferral;
