import React, { useEffect, useState } from "react";
import { Row, Col, Button, FormGroup, Form, Input, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Country, State, City } from "country-state-city";
import * as yup from "yup";
import { ChevronLeft } from "react-feather";
import eyeshowicon from "../../../assets/image/eye-show.svg";
import eyehideicon from "../../../assets/image/eye-hide1.svg";
import { API_URL } from "../../../helper/API_URL";
import { Helper, validateFields } from "../../../helper/HelperFunction";
import { useSelector } from "react-redux";
import Select from "react-select";
import toast from "react-hot-toast";

const defaultErrorValues = {
  first_name: false,
  last_name: false,
  email: false,
  phone_number: false,
  country: false,
  state: false,
  city: false,
  zip_code: false,
  password: false,
  confirm_password: false,
  policy: false,
  school_id: false,
  school_name: false,
  gpa: false,
  sat_act_scores: false,
  position: false,
  linkedin_profile: false,
  graduation_year: false,
  major: false,
};
const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  password: "",
  country_code: "",
  state_code: "",
  school_name: "",
  school_id: "",
  graduation_year: "",
  gpa: "",
  sat_act_scores: "",
  position: "",
  linkedin_profile: "",
  is_officer: false,
  major: [],
};
const signupSchema = yup.object().shape({
  is_officer: yup.boolean().required(),
  first_name: yup.string().required("Enter first name"),
  last_name: yup.string().required("Enter last name"),
  email: yup.string().email("Invalid Email Format").required("Enter email"),
  phone_number: yup
    .string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .min(10, "Mobile number must be 10 digits")
    .max(10, "Mobile number must be 10 digits"),
  city: yup.string().required("Select a city"),
  state: yup.string().required("Select a state"),
  country: yup.string().required("Select a country"),
  zip_code: yup.string().required("Enter zip code"),
  school_id: yup.string().required("Enter school id"),
  school_name: yup.string().required("Enter school name"),
  gpa: yup.string().nullable(),
  sat_act_scores: yup.string().nullable(),
  position: yup.string().nullable(),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%_#*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "The password must be atleast 8 characters long and include one lowercase letter, one uppercase letter, one digit, and one special characters."
    )
    .required("Enter password"),
  graduation_year: yup.string().when("is_officer", {
    is: (val) => val,
    then: (s) => s.nullable(),
    otherwise: (s) => s.required("Select graduation year"),
  }),
  major: yup.array().when("is_officer", {
    is: (val) => val,
    then: (s) => s.nullable(),
    otherwise: (s) => s.min(1).required("Select major"),
  }),
});
const Adduser = (args) => {
  const { Major } = useSelector((state) => state.Major);
  const navigate = useNavigate();
  const [country, setCountry] = useState(Country.getAllCountries());
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [formObj, setFormObj] = useState(defaultValues);
  const [errorObj, setErrorObj] = useState(defaultErrorValues);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (Major?.length > 0) {
      const data = Major?.map((major) => ({
        label: major.name,
        value: major._id,
      }));
      setOptions(data);
    }
  }, [Major]);
  const handleInputChange = (event) => {
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;
    if (name === "country") {
      const countryInput = value.split("&");
      setFormObj((prev) => ({
        ...prev,
        country: countryInput[1],
        country_code: countryInput[0],
      }));
      setState(() => State.getStatesOfCountry(countryInput[0]));
    } else if (name === "state") {
      const stateInput = value.split("&");
      setFormObj((prev) => ({
        ...prev,
        state: stateInput[1],
        state_code: stateInput[0],
      }));
      setCity(() => City.getCitiesOfState(formObj.country_code, stateInput[0]));
    } else if (name === "city") {
      setFormObj((prev) => ({ ...prev, city: value }));
    } else if (name === "is_officer") {
      setFormObj({
        ...formObj,
        is_officer: value === "true" ? true : false,
      });
    } else {
      setFormObj({
        ...formObj,
        [name]: value.trimStart(),
      });
    }
  };
  const handleChange = (selected) => {
    setErrorObj(defaultErrorValues);
    if (selected.length < 6) {
      setFormObj((prev) => ({ ...prev, major: selected }));
    } else {
      setErrorObj((prev) => ({
        ...prev,
        major: "You can select upto 5 majors",
      }));
    }
  };
  // console.log(4477,country);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      const errors = await validateFields(signupSchema, formObj);
      if (Object.keys(errors).length > 0) {
        return setErrorObj(errors);
      }
      if (formObj.password !== formObj.confirm_password) {
        setLoading(false);
        return setErrorObj((prev) => ({
          ...prev,
          confirm_password: "Password and confirm password should be same",
        }));
      }
      const url = API_URL.CREATE_USER;
      const obj = {
        is_officer: formObj.is_officer,
        first_name: formObj.first_name,
        last_name: formObj.last_name,
        email: formObj.email,
        phone_number: formObj.phone_number,
        country: formObj.country,
        state: formObj.state,
        city: formObj.city,
        zip_code: formObj.zip_code,
        password: formObj.password,
        country_code: formObj.country_code,
        state_code: formObj.state_code,
        school_name: formObj.school_name,
        school_id: formObj.school_id,
        ...(!formObj.is_officer && {
          graduation_year: formObj.graduation_year,
          gpa: formObj.gpa,
          sat_act_scores: formObj.sat_act_scores,
          major: formObj.major.map((val) => val.value),
        }),
        ...(formObj.is_officer && {
          position: formObj.position,
          linkedin_profile: formObj.linkedin_profile,
        }),
      };
      const onSuccess = (data) => {
        if (data) {
          navigate("/users");
          toast.success("User created successfully");
        }
      };
      const onError = (res) => {
        toast.error(res?.data?.message);
      };
      await Helper.PostAPI(url, obj, onSuccess, onError);
    } catch (err) {
      console.log(err);
      toast.error("App Error");
    } finally {
      setLoading(false);
    }
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  console.log("role", formObj);
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8" className="breadcrumbtop">
            <Link to="/Users">
              <ChevronLeft />
            </Link>
            <div>
              <h1>Add User</h1>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipisici elit</p> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="8">
            <Form onSubmit={handleSubmit}>
              <Row>
                {/* <Col md="12" lg="12">
                  <div className="user-avatar-section mt-5">
                    <img className="useravatarimg" src={profileImg} alt="" />
                    <Button
                      id="change-img"
                      tag={Label}
                      outline
                      className="edit_profile"
                    >
                      <Edit2 />
                      <input
                        type="file"
                        hidden
                        id="change-img"
                        accept="image/*"
                        onChange={handleImageInput}
                      />
                    </Button>
                  </div>
                </Col> */}
                <Col md="12" lg="12" className="mt-3">
                  <FormGroup className="form-group">
                    <Label className="form-label mb-1">User Type</Label>
                    <Input
                      name="is_officer"
                      value={formObj.is_officer}
                      onChange={handleInputChange}
                      type="select"
                    >
                      <option value="false">Student</option>
                      <option value="true">Admission Officer</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label mb-1">First Name</Label>
                    <Input
                      name="first_name"
                      placeholder="First Name"
                      value={formObj.first_name}
                      onChange={handleInputChange}
                      type="text"
                    />
                    {errorObj.first_name && (
                      <div className="error">{errorObj.first_name}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label  mb-1">Last Name</Label>
                    <Input
                      name="last_name"
                      placeholder="Last Name"
                      value={formObj.last_name}
                      onChange={handleInputChange}
                      type="text"
                    />
                    {errorObj.last_name && (
                      <div className="error">{errorObj.last_name}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label  mb-1">Email Address</Label>
                    <Input
                      name="email"
                      placeholder="Email Address"
                      value={formObj.email}
                      onChange={handleInputChange}
                      type="email"
                    />
                    {errorObj.email && (
                      <div className="error">{errorObj.email}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label  mb-1">Phone No.</Label>
                    <Input
                      name="phone_number"
                      placeholder="Phone No"
                      value={formObj.phone_number}
                      onChange={handleInputChange}
                      type="tel"
                    />
                    {errorObj.phone_number && (
                      <div className="error">{errorObj.phone_number}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label  mb-1">Country</Label>
                    <Input
                      type="select"
                      name="country"
                      value={`${formObj.country_code}&${formObj.country}`}
                      onChange={handleInputChange}
                    >
                      <option value="select">Select One</option>
                      {country?.map((cnt) => {
                        return (
                          <option value={`${cnt.isoCode}&${cnt.name}`}>
                            {cnt.name}
                          </option>
                        );
                      })}
                    </Input>
                    {errorObj.country && (
                      <div className="error">{errorObj.country}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label  mb-1">State</Label>
                    <Input
                      type="select"
                      name="state"
                      value={`${formObj.state_code}&${formObj.state}`}
                      onChange={handleInputChange}
                    >
                      <option value="select">Select One</option>
                      {state?.map((stt) => {
                        return (
                          <option value={`${stt.isoCode}&${stt.name}`}>
                            {stt.name}
                          </option>
                        );
                      })}
                    </Input>
                    {errorObj.state && (
                      <div className="error">{errorObj.state}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label  mb-1">City</Label>
                    <Input
                      type="select"
                      name="city"
                      value={formObj.city}
                      onChange={handleInputChange}
                    >
                      <option value="select">Select One</option>
                      {city?.map((city) => {
                        return <option value={city.name}>{city.name}</option>;
                      })}
                    </Input>
                    {errorObj.city && (
                      <div className="error">{errorObj.city}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label mb-1">Zip code</Label>
                    <Input
                      name="zip_code"
                      placeholder="Enter zip code"
                      value={formObj.zip_code}
                      onChange={handleInputChange}
                    />
                    {errorObj.zip_code && (
                      <div className="error">{errorObj.zip_code}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label mb-1">
                      {formObj.is_officer ? "Institute" : "School"} Name
                    </Label>
                    <Input
                      name="school_name"
                      placeholder={`Enter ${
                        formObj.is_officer ? "institute" : "school"
                      } name`}
                      value={formObj.school_name}
                      onChange={handleInputChange}
                    />
                    {errorObj.school_name && (
                      <div className="error">{errorObj.school_name}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label mb-1">
                      {formObj.is_officer ? "Institute" : "School"} ID Number
                    </Label>
                    <Input
                      name="school_id"
                      placeholder={`Enter ${
                        formObj.is_officer ? "institute" : "school"
                      } id number`}
                      value={formObj.school_id}
                      onChange={handleInputChange}
                    />
                    {errorObj.school_id && (
                      <div className="error">{errorObj.school_id}</div>
                    )}
                  </FormGroup>
                </Col>
                {!formObj.is_officer && (
                  <>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group position-relative">
                        <Label className="form-label mb-1">
                          Graduation Year
                        </Label>
                        <Datetime
                          dateFormat="YYYY"
                          timeFormat={false}
                          inputProps={{
                            placeholder: "Select Year",
                            readOnly: true,
                          }}
                          value={
                            formObj.graduation_year
                              ? new Date(formObj.graduation_year, 0, 1)
                              : ""
                          }
                          closeOnSelect={true}
                          viewMode="years"
                          onChange={(date) =>
                            setFormObj((prev) => ({
                              ...prev,
                              graduation_year: date.year()?.toString(),
                            }))
                          }
                        />
                        {errorObj.graduation_year && (
                          <div className="error">
                            {errorObj.graduation_year}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group position-relative">
                        <Label className="form-label mb-1">Major</Label>
                        <Select
                          isMulti
                          name="major"
                          options={options}
                          value={formObj.major}
                          onChange={handleChange}
                          styles={{
                            control: (val) => ({
                              ...val,
                              minHeight: "45px",
                              borderRadius: "5px",
                            }),
                          }}
                        />
                        {errorObj.major && (
                          <div className="error">{errorObj.major}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group position-relative">
                        <Label className="form-label mb-1">
                          GPA (optional)
                        </Label>
                        <Input
                          type="text"
                          name="gpa"
                          placeholder="Enter GPA"
                          value={formObj.gpa}
                          onChange={handleInputChange}
                        />
                        {errorObj.gpa && (
                          <div className="error">{errorObj.gpa}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group position-relative">
                        <Label className="form-label mb-1">
                          SAT/ACT Scores (optional)
                        </Label>
                        <Input
                          type="text"
                          name="sat_act_scores"
                          placeholder="Enter SAT/ACT scores"
                          value={formObj.sat_act_scores}
                          onChange={handleInputChange}
                        />
                        {errorObj.sat_act_scores && (
                          <div className="error">{errorObj.sat_act_scores}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </>
                )}
                {formObj.is_officer && (
                  <>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group position-relative">
                        <Label className="form-label mb-1">Position</Label>
                        <Input
                          type="text"
                          name="position"
                          placeholder="Enter position"
                          value={formObj.position}
                          onChange={handleInputChange}
                        />
                        {errorObj.position && (
                          <div className="error">{errorObj.position}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group position-relative">
                        <Label className="form-label mb-1">
                          LinkedIn Profile (Optional)
                        </Label>
                        <Input
                          type="text"
                          name="linkedin_profile"
                          placeholder="Enter linkedin profile"
                          value={formObj.linkedin_profile}
                          onChange={handleInputChange}
                        />
                        {errorObj.linkedin_profile && (
                          <div className="error">
                            {errorObj.linkedin_profile}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </>
                )}
                <Col md="12" lg="6">
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label mb-1">Password</Label>
                    <Input
                      name="password"
                      placeholder="Password"
                      type={showPassword1 ? "text" : "password"}
                      value={formObj.password}
                      onChange={handleInputChange}
                    />
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility1}
                    >
                      {showPassword1 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                    {errorObj.password && (
                      <div className="error">{errorObj.password}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label mb-1">Confirm Password</Label>
                    <Input
                      name="confirm_password"
                      placeholder="Confirm Password"
                      type={showPassword2 ? "text" : "password"}
                      value={formObj.confirm_password}
                      onChange={handleInputChange}
                    />
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility2}
                    >
                      {showPassword2 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                    {errorObj.confirm_password && (
                      <div className="error">
                        {"Password and Confirm Password should be same"}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex">
                <Link to="/Users">
                  <Button className="cancel_btn mt-4 me-2" type="submit">
                    Cancel
                  </Button>
                </Link>
                <Button
                  className="login_btn mt-4"
                  type="submit"
                  color="primary"
                >
                  Add
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Adduser;
