import { useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";

const ActionButton = ({ id, icon, tooltipText, className, link }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
  
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  
    return (
      <>
        <Link
          className={`actionbtn ${className}`}
          id={`${id}Tooltip`}
          to={`${link}`}
        >
          <img src={icon} alt={tooltipText} />
          <Tooltip
            placement="bottom"
            isOpen={tooltipOpen}
            target={`${id}Tooltip`}
            toggle={toggleTooltip}
          >
            {tooltipText}
          </Tooltip>
        </Link>
      </>
    );
  };
  export default ActionButton;