import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import edit from "../../assets/image/view.svg";
import not_found from "../../assets/image/not_found.svg";
import remove from "../../assets/image/delete.png";
import download from "../../assets/image/download.png";
import { capitalizeEveryWord, Helper } from "../../helper/HelperFunction";
import { API_URL } from "../../helper/API_URL";
import PageLoader from "../../components/PageLoader";
import ActionButton from "../Include/ActionButton";
import { Link } from "react-router-dom";
import AddReferral from "./AddReferral";
import toast from "react-hot-toast";

const DataTable1 = ({ data1, currentPage, itemsPerPage, fetchUser, view }) => {
  const Permissions = useSelector((state) => state.Permissions);

  const handleBlock = async (id) => {
    try {
      const url = `${API_URL.DELETE_REFERRAL}?id=${id}`;
      const user = await Helper.DeleteData(url);
      if (user.data.status) {
        fetchUser();
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleDownloadExcel = async(id,name) => {
    const tokens = await Helper.GetData(`${API_URL.TOKEN_LIST}?id=${id}`)
    if(!tokens.data?.status){
      return toast.error("Error downloading tokens")
    }
    const groupName = `Name:-  ${capitalizeEveryWord(name)}`;
    const data = tokens.data.data.map((item)=>({inviteToken:item.name}));
    const wb = XLSX.utils.book_new();
    const firstRow = [[groupName, ""]];
    const secondRow = ["S.No", "Invite Token"];
    const dataRows = data.map((item, index) => [index + 1, item.inviteToken]);
    const worksheetData = [...firstRow, secondRow, ...dataRows];
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    ws["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!ws[cellAddress]) continue;
        if (R === 0) {
          ws[cellAddress].s = {
            font: { bold: true },
            alignment: { horizontal: "center", vertical: "center" },
          };
        }
        if (R === 1) {
          ws[cellAddress].s = {
            font: { bold: true },
            alignment: { horizontal: "center", vertical: "center" },
          };
        }
        if (R > 1) {
          ws[cellAddress].s = {
            alignment: { horizontal: "center", vertical: "center" },
          };
        }
      }
    }
    XLSX.utils.book_append_sheet(wb, ws, "InviteTokens");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "InviteTokens.xlsx");
  };
  return (
    <>
      <div className="table-responsive">
        {data1?.length > 0 ? (
          <table className="table tablecustom">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Referral Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data1?.map((item, index) => (
                <tr key={item.id}>
                  {/* <td>{item.id}</td> */}
                  <td>{index + 1}</td>
                  <td className="text-capitalize">{item.name}</td>
                  <td>
                    {/* <ActionButton id={`view-${index}`} icon={view} tooltipText="View" className="view-btn" link="#"/> */}
                    <ActionButton
                      id={`view-${index}`}
                      icon={edit}
                      tooltipText="View"
                      className="view-btn"
                      link={`view?key=${item._id}`}
                    />
                    <span onClick={() => handleDownloadExcel(item._id,item.name)}>
                      <ActionButton
                        id={`download-${index}`}
                        icon={download}
                        tooltipText="Download"
                        className="block-btn"
                        link="#"
                      />
                    </span>
                    {Permissions.User?.delete && (
                      <span onClick={() => handleBlock(item._id)}>
                        <ActionButton
                          id={`block-${index}`}
                          icon={remove}
                          tooltipText="Delete"
                          className="delete-btn"
                          link="#"
                        />
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="noresult_div">
            <img src={not_found} />
            <h3>No Data Found</h3>
          </div>
        )}
      </div>
      {/* )} */}
    </>
  );
};
const Referral = (args) => {
  const [data1, setData1] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [view, setView] = useState("");
  const [sort, setSort] = useState("");
  const [show, setShow] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchUser();
    setCurrentPage(1);
  }, [searchTerm, sort]);
  const toggle = () => setShow(!show);
  const fetchUser = async () => {
    try {
      const url = `${API_URL.REFERRAL_LIST}?filter=${searchTerm}&sort=${sort}`;
      const usr = await Helper.GetData(url);
      if (usr.data.status === true) {
        setData1(() => usr.data.data.referrals);
        setTotal(usr.data.data.total_count);
      } else {
        setData1([]);
      }
    } catch (err) {
      console.log("Error", err);
      setData1([]);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  console.log({ currentPage, data1 });
  return !data1 ? (
    <PageLoader />
  ) : (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>Referrals</h1>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisici elit</p> */}
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row className="mb-3">
          {/* <Col md="6" lg="3" className="align-self-end">
            <div className="form-group mb-3">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                className="form-control"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </Col>
          <Col md="6" lg="3">
            <div className="form-group mb-3">
              <Input
                id="sortby"
                name="sortby"
                type="select"
                className="form-control"
                onChange={(e) => setSort(e.target.value)}
              >
                <option value="">Sort By</option>
                <option value="nameasc">Name (Asc)</option>
                <option value="namedesc">Name (Desc)</option>
                <option value="latestfirst">Latest First</option>
                <option value="latestlast">Oldest First</option>
              </Input>
            </div>
          </Col> */}
          <Col>
            <h4>List</h4>
          </Col>
          <Col md="6" lg="6" className="text-end text-md-end align-self-end ">
            {/* <Link to="add" className="mb-3 d-inline-block"> */}
            <Button color="primary" className="px-3" onClick={toggle}>
              Add
            </Button>
            {/* </Link> */}
          </Col>
        </Row>
        <DataTable1
          data1={data1}
          searchTerm={searchTerm}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setFilteredData={setFilteredData}
          fetchUser={fetchUser}
          view={view || "list"}
        />
        <div className="mb-3">
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              ‹
            </button>
            {Array.from({
              length: Math.ceil(total / itemsPerPage),
            }).map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(total / itemsPerPage)}
            >
              ›
            </button>
          </div>
        </div>
      </div>
      <AddReferral show={show} onHide={toggle} />
    </>
  );
};

export default Referral;
