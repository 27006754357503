import { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

const Layout = lazy(() => import("../components/Layout/index.jsx"));
const Blocked = lazy(() => import("../pages/authentication/Blocked.jsx"));
const ChangePassword = lazy(() =>
  import("../pages/AccountSettings/ChangePassword/index.jsx")
);
const ProfileSetting = lazy(() => import("../pages/ProfileSetting/index.jsx"));
const Edituser = lazy(() => import("../pages/Users/Edituser/index.jsx"));
const Posts = lazy(() => import("../pages/Posts/Posts.jsx"));
const PostDetails = lazy(() => import("../pages/Posts/PostDetail.jsx"));
const Comments = lazy(() => import("../pages/Comments/comments.jsx"));
const CommentDetails = lazy(() =>
  import("../pages/Comments/commentDetail.jsx")
);
const Groups = lazy(() => import("../pages/Groups/Groups.jsx"));
const GroupDetails = lazy(() => import("../pages/Groups/GroupDetail.jsx"));
const Projects = lazy(() => import("../pages/PassionProjects/Projects.jsx"));
const ProjectDetails = lazy(() =>
  import("../pages/PassionProjects/ProjectDetail.jsx")
);
const Users = lazy(() => import("../pages/Users/index.jsx"));
const OfficerDashboard = lazy(() =>
  import("../officer/screens/Dashboard/index.jsx")
);

const SchoolRoute = () => {
  // const Permissions = useSelector((state) => state.Permissions);
  return (
    <Layout>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<OfficerDashboard />} />
        <Route path="/profile" element={<ProfileSetting />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/no-auth" element={<Blocked />} />
        <Route path="/students" element={<Users />} />
        <Route path="/students/view" element={<Edituser />} />
        <Route path="/officers" element={<Users />} />
        <Route path="/officers/view" element={<Edituser />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/view" element={<PostDetails />} />
        <Route path="/comments" element={<Comments />} />
        <Route path="/comments/view" element={<CommentDetails />} />
        <Route path="/groups" element={<Groups />} />
        <Route path="/groups/view" element={<GroupDetails />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/view" element={<ProjectDetails />} />
      </Routes>
    </Layout>
  );
};

export default SchoolRoute;
