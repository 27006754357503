import { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Major from "../pages/Content/Major/major.jsx";
import AddMajor from "../pages/Content/Major/AddMajor.jsx";
import EditMajor from "../pages/Content/Major/EditMajor.jsx";
import Adduser from "../pages/Users/Adduser/index.jsx";
import Referral from "../pages/Referral/index.jsx";
import AddReferral from "../pages/Referral/AddReferral/index.jsx";
import Tokens from "../pages/Referral/tokens.jsx";
import ReferralView from "../pages/Referral/referralView.jsx";

const Layout = lazy(() => import("../components/Layout/index.jsx"));
const Blocked = lazy(() => import("../pages/authentication/Blocked.jsx"));
const ChangePassword = lazy(() =>
  import("../pages/AccountSettings/ChangePassword/index.jsx")
);
const ProfileSetting = lazy(() => import("../pages/ProfileSetting/index.jsx"));
const Edituser = lazy(() => import("../pages/Users/Edituser/index.jsx"));
const Posts = lazy(() => import("../pages/Posts/Posts.jsx"));
const PostDetails = lazy(() => import("../pages/Posts/PostDetail.jsx"));
const Comments = lazy(() => import("../pages/Comments/comments.jsx"));
const CommentDetails = lazy(() =>
  import("../pages/Comments/commentDetail.jsx")
);
const Groups = lazy(() => import("../pages/Groups/Groups.jsx"));
const GroupDetails = lazy(() => import("../pages/Groups/GroupDetail.jsx"));
const Chats = lazy(() => import("../pages/Chats/Chats.jsx"));
const ChatDetails = lazy(() => import("../pages/Chats/ChatDetails.jsx"));
const Projects = lazy(() => import("../pages/PassionProjects/Projects.jsx"));
const ProjectDetails = lazy(() =>
  import("../pages/PassionProjects/ProjectDetail.jsx")
);
const Reports = lazy(() => import("../pages/Report/index.jsx"));
const Users = lazy(() => import("../pages/Users/index.jsx"));
const Role = lazy(() => import("../pages/AccountSettings/Role/index.jsx"));
const AddRole = lazy(() =>
  import("../pages/AccountSettings/Role/Addrole/index.jsx")
);
const EditRole = lazy(() =>
  import("../pages/AccountSettings/Role/Editrole/index.jsx")
);
const EditResource = lazy(() =>
  import("../pages/Content/Resources/EditResource.jsx")
);
const AddResource = lazy(() =>
  import("../pages/Content/Resources/AddResource.jsx")
);
const Resource = lazy(() => import("../pages/Content/Resources/Resource.jsx"));
const EditResourceCat = lazy(() =>
  import("../pages/Content/Resources/EditRCat.jsx")
);
const AddResourceCat = lazy(() =>
  import("../pages/Content/Resources/AddRCat.jsx")
);
const ResourceCat = lazy(() =>
  import("../pages/Content/Resources/RCategory.jsx")
);
const EditFaq = lazy(() => import("../pages/Content/FAQs/EditFaq.jsx"));
const AddFaq = lazy(() => import("../pages/Content/FAQs/AddFaq.jsx"));
const EditFaqCat = lazy(() => import("../pages/Content/FAQs/EditFaqCat.jsx"));
const AddFaqCat = lazy(() => import("../pages/Content/FAQs/AddFaqCat.jsx"));
const FaqCat = lazy(() => import("../pages/Content/FAQs/FaqCat.jsx"));
const AddTerms = lazy(() => import("../pages/Content/Terms/AddTerms.jsx"));
const AdminPostDetails = lazy(() =>
  import("../pages/Content/Post/PostDetail.jsx")
);
const Organizations = lazy(() => import("../pages/Organization/index.jsx"));
const AddOrganization = lazy(() =>
  import("../pages/Organization/Adduser/index.jsx")
);
const EditOrganization = lazy(() =>
  import("../pages/Organization/Edituser/index.jsx")
);
const Privacy = lazy(() =>
  import("../pages/Content/Privacy/PrivacyPolicy.jsx")
);
const Terms = lazy(() =>
  import("../pages/Content/Terms/TermsAndConditions.jsx")
);
const AddPrivacy = lazy(() =>
  import("../pages/Content/Privacy/AddPrivacy.jsx")
);
const Faq = lazy(() => import("../pages/Content/FAQs/Faq.jsx"));
const AdminPosts = lazy(() => import("../pages/Content/Post/Posts.jsx"));
const AddPost = lazy(() => import("../pages/Content/Post/AddPost.jsx"));
const Dashboard = lazy(() => import("../pages/Dashboard/index.jsx"));
const School = lazy(() => import("../pages/Content/School/school.jsx"));
const AddSchool = lazy(() => import("../pages/Content/School/AddSchool.jsx"));
const EditSchool = lazy(() => import("../pages/Content/School/old/EditSchool.jsx"));
const OfficerDashboard = lazy(() => import("../officer/screens/Dashboard/index.jsx"));

const PrivateRoute = () => {
  const Permissions = useSelector((state) => state.Permissions);
  return (
    <Layout>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route
          path="/"
          element={Permissions.Dashboard?.view ? <Dashboard /> : <Blocked />}
        />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/school/details" element={<OfficerDashboard />} />
        <Route path="/profile" element={<ProfileSetting />} />
        <Route path="/no-auth" element={<Blocked />} />
        <Route
          path="/users/view"
          element={Permissions.User?.view ? <Edituser /> : <Blocked />}
        />
        <Route
          path="/posts"
          element={Permissions.Post?.view ? <Posts /> : <Blocked />}
        />
        <Route
          path="/posts/view"
          element={Permissions.Post?.view ? <PostDetails /> : <Blocked />}
        />
        <Route
          path="/comments"
          element={Permissions.Comment?.view ? <Comments /> : <Blocked />}
        />
        <Route
          path="/comments/view"
          element={Permissions.Comment?.view ? <CommentDetails /> : <Blocked />}
        />
        <Route
          path="/groups"
          element={Permissions.Group?.view ? <Groups /> : <Blocked />}
        />
        <Route
          path="/groups/view"
          element={Permissions.Group?.view ? <GroupDetails /> : <Blocked />}
        />
        <Route
          path="/chats"
          element={Permissions.Chat?.view ? <Chats /> : <Blocked />}
        />
        <Route
          path="/chats/view"
          element={Permissions.Chat?.view ? <ChatDetails /> : <Blocked />}
        />
        <Route
          path="/projects"
          element={
            Permissions["My Passion Project"]?.view ? <Projects /> : <Blocked />
          }
        />
        <Route
          path="/projects/view"
          element={
            Permissions["My Passion Project"]?.view ? (
              <ProjectDetails />
            ) : (
              <Blocked />
            )
          }
        />
        <Route
          path="/reports"
          element={Permissions.Report?.view ? <Reports /> : <Blocked />}
        />
        <Route
          path="/users"
          element={Permissions.User?.view ? <Users /> : <Blocked />}
        />
        <Route
          path="/users/add"
          element={Permissions.User?.view ? <Adduser /> : <Blocked />}
        />
        <Route
          path="/role"
          element={Permissions.Role?.view ? <Role /> : <Blocked />}
        />
        <Route
          path="/role/add-role"
          element={Permissions.Role?.add ? <AddRole /> : <Blocked />}
        />
        <Route
          path="/role/edit-role"
          element={Permissions.Role?.edit ? <EditRole /> : <Blocked />}
        />
        <Route
          path="/referral"
          element={Permissions.Referral?.view ? <Referral /> : <Blocked />}
        />
        <Route
          path="/referral/add"
          element={Permissions.Referral?.add ? <AddReferral /> : <Blocked />}
        />
        <Route
          path="/referral/tokens"
          element={<Tokens />}
        />
        <Route
          path="/referral/view"
          element={<ReferralView />}
        />
        <Route
          path="/organization-users"
          element={
            Permissions["Organization User"]?.view ? (
              <Organizations />
            ) : (
              <Blocked />
            )
          }
        />
        <Route
          path="/organization-users/add"
          element={
            Permissions["Organization User"]?.add ? (
              <AddOrganization />
            ) : (
              <Blocked />
            )
          }
        />
        <Route
          path="/organization-users/edit"
          element={
            Permissions["Organization User"]?.edit ? (
              <EditOrganization />
            ) : (
              <Blocked />
            )
          }
        />
        <Route
          path="/privacy-policy"
          element={
            Permissions["Privacy Policy"]?.view ? <Privacy /> : <Blocked />
          }
        />
        <Route
          path="/term-conditions"
          element={
            Permissions["Term & Condition"]?.view ? <Terms /> : <Blocked />
          }
        />
        <Route
          path="/privacy-policy/edit"
          element={
            Permissions["Privacy Policy"]?.edit ? <AddPrivacy /> : <Blocked />
          }
        />
        <Route
          path="/term-conditions/edit"
          element={
            Permissions["Term & Condition"]?.edit ? <AddTerms /> : <Blocked />
          }
        />
        <Route
          path="/admin-post"
          element={
            Permissions["Admin Post"]?.view ? <AdminPosts /> : <Blocked />
          }
        />
        <Route
          path="/admin-post/view"
          element={
            Permissions["Admin Post"]?.view ? <AdminPostDetails /> : <Blocked />
          }
        />
        <Route
          path="/admin-post/add"
          element={Permissions["Admin Post"]?.add ? <AddPost /> : <Blocked />}
        />
        <Route
          path="/faq-cat"
          element={Permissions.Faq?.view ? <FaqCat /> : <Blocked />}
        />
        <Route
          path="/faq-cat/add"
          element={Permissions.Faq?.add ? <AddFaqCat /> : <Blocked />}
        />
        <Route
          path="/faq-cat/edit"
          element={Permissions.Faq?.edit ? <EditFaqCat /> : <Blocked />}
        />
        <Route
          path="/faq-cat/faq"
          element={Permissions.Faq?.view ? <Faq /> : <Blocked />}
        />
        <Route
          path="/faq-cat/faq/add"
          element={Permissions.Faq?.add ? <AddFaq /> : <Blocked />}
        />
        <Route
          path="/faq-cat/faq/edit"
          element={Permissions.Faq?.edit ? <EditFaq /> : <Blocked />}
        />
        <Route
          path="/resource-cat"
          element={Permissions.Resource?.view ? <ResourceCat /> : <Blocked />}
        />
        <Route
          path="/resource-cat/add"
          element={Permissions.Resource?.add ? <AddResourceCat /> : <Blocked />}
        />
        <Route
          path="/resource-cat/edit"
          element={
            Permissions.Resource?.edit ? <EditResourceCat /> : <Blocked />
          }
        />
        <Route
          path="/resource-cat/resource"
          element={Permissions.Resource?.view ? <Resource /> : <Blocked />}
        />
        <Route
          path="/resource-cat/resource/add"
          element={Permissions.Resource?.add ? <AddResource /> : <Blocked />}
        />
        <Route
          path="/resource-cat/resource/edit"
          element={Permissions.Resource?.edit ? <EditResource /> : <Blocked />}
        />
        {/* <Route
          path="/school"
          element={Permissions.School?.view ? <School /> : <Blocked />}
        /> */}
        <Route
          path="/school"
          element={Permissions.School?.view ? <School /> : <Blocked />}
        />
        <Route
          path="/school/add"
          element={Permissions.School?.add ? <AddSchool /> : <Blocked />}
        />
        <Route
          path="/school/edit"
          element={Permissions.School?.edit ? <EditSchool /> : <Blocked />}
        />
        <Route
          path="/major"
          element={Permissions.Major?.view ? <Major /> : <Blocked />}
        />
        <Route
          path="/major/add"
          element={Permissions.Major?.add ? <AddMajor /> : <Blocked />}
        />
        <Route
          path="/major/edit"
          element={Permissions.Major?.edit ? <EditMajor /> : <Blocked />}
        />
      </Routes>
    </Layout>
  );
};

export default PrivateRoute;
