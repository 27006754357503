import {configureStore} from "@reduxjs/toolkit"
import userReducer from "./userSlice";
import permissionReducer from "./permissionSlice";
import majorReducer from "./majorSlice";

export const store = configureStore({
    reducer:{
        user:userReducer,
        Permissions:permissionReducer,
        Major:majorReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false}),
});