import React from "react";
import LoaderImg from "../assets/image/briddg-logo.png"
import "./Loader.css"

const Loader=() =>{
  return (
    <div className="preloader">
      <div className="loading">
        <img src={LoaderImg} />
      </div>
    </div>
  );
}

export default Loader;
