import React, { useEffect, useState } from "react";
import { Row, Col, Label, Form, FormGroup, Input, Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";

import * as yup from "yup";
import { Helper, validateFields } from "../../../helper/HelperFunction";
import { API_URL } from "../../../helper/API_URL";
import { useNavigate, Link } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import { useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader";

const defaultValues = {
  name: ""
};
const defaultErrorValues = {
  name: false
};
const roleSchema = yup.object().shape({
  name: yup.string().required("Enter major")
});
const EditMajor = () => {
  const Permissions = useSelector((state) => state.Permissions);
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const major_id = query.get("key");
  const [formObj, setFormObj] = useState(defaultValues);
  const [erroObj, setErrorObj] = useState(defaultErrorValues);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const url = `${API_URL.GET_MAJOR}?id=${major_id}`;
      const result = await Helper.GetData(url);
      if (result.data.status) {
        setFormObj(result.data.data.result[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (event) => {
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;
    setFormObj({
      ...formObj,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const errors = await validateFields(roleSchema, formObj);
      if (Object.keys(errors).length > 0) {
        return setErrorObj(errors);
      }
      const url = API_URL.UPDATE_MAJOR;
      const obj = {
        id:major_id,
        name: formObj.name
      };
      const result = await Helper.PutData(url, obj);
      if (result.data?.status) {
        setSaving(false);
        navigate(-1);
      } else {
        setErrorObj((prev) => ({
          ...prev,
          name: result.response?.data?.message,
        }));
        setSaving(false);
      }
    } catch (err) {
      setSaving(false);
    }
  };
  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="welcome_Div">
            <Row>
              <Col lg="8" className="breadcrumbtop">
                <Link onClick={() => navigate(-1)}>
                  <ChevronLeft />
                </Link>
                <div>
                  <h1>Edit Major</h1>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col lg="8">
              <Form onSubmit={handleSubmit}>
                <FormGroup className="form-group">
                  <Label className="mb-1">Major</Label>
                  <Input
                    className="form-control"
                    name="name"
                    value={formObj.name}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter major"
                  ></Input>
                  {erroObj.name && <div className="error">{erroObj.name}</div>}
                </FormGroup>
                <div>
                  <Button
                    className="cancel_btn px-3 min-width-auto me-1"
                    onClick={(e) => navigate(-1)}
                  >
                    Back
                  </Button>
                  {Permissions.Role?.edit && (
                    <Button
                      className="login_btn  px-3 min-width-auto ms-1"
                      color="primary"
                      type="submit"
                    >
                      {saving?"Saving...":"Save"}
                    </Button>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default EditMajor;
