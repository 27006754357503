import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../helper/API_URL";
import { Helper } from "../helper/HelperFunction";
import { UpdatePermissions } from "./permissionSlice";

const initialState = {
  Usertoken: "",
  zip_code: "",
  _id: "",
  first_name: "",
  last_name: "",
  email: "",
  profile_pic: "",
  pnone_number: "",
  role: "",
  city: "",
  state: "",
  country: "",
  country_code: "",
  state_code: "",
};
const fetchUserData = createAsyncThunk(
  "userDetails/fetchUserData",
  async (_,{dispatch}) => {
    try {
      const url = API_URL.PROFILE;
      const result = await Helper.GetData(url);
      const data = result.data.data;
      dispatch(UpdatePermissions(data.permissions.permissions))
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    UpdateUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    LogedOut: (state) => {
      return { ...state, ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      const { users } = action.payload;
      const userToken =
        window.localStorage.getItem("userToken") ||
        window.sessionStorage.getItem("userToken");
    return {
        ...state,
        ...users,
        Usertoken:userToken
    }
    });
  },
});

export const { UpdateUser, LogedOut } = userSlice.actions;
export {fetchUserData};
export default userSlice.reducer;
